export function getContractFieldValue(dateNowFormatMessage) {
  return {
    author: `ОсОО "МТО "ХХХ"`,
    authorName: `Общество с ограниченной ответственностью "МТО "Cевер"`,
    clientName: 'ОсОО «Авиа Трафик Компани»',
    date: dateNowFormatMessage(new Date()),
    city: 'Бишкек',
    street: 'Кожевенная, дом д.№74,',
    number: '1',
    OKPO: '30948819',
    RS: '1090820114770207',
    inn: '02610202010113',
    bik: '109008',
    FOAO: '«Оптима Банк»',
    mobillePhone: '0557 23-23-23',
    email: 'bts_tt@mail.ru',
  };
}

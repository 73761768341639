// external dependencies
import { Col, Row } from 'atomize';
import React from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import CalendarIcon from '../../../assets/svg/calendar';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';
import { dateNowFormatMessage } from '../../common/DateTime';
import FormField from '../../form/FormField';
import { ContractType } from '../createContracts/redux/types';
import { GetContractState } from './redux/reducer';

export default function HeaderContract({ isDate = true }) {
  const { contract } = useSelector(
    (state: RootState): GetContractState => state.getContract,
  );

  const inputLabel = {
    supervisorLabel: formatMessage('supervisor'),
    country: formatMessage('country'),
    city: formatMessage('city'),
    positionLabel: formatMessage('position'),
    commencementionContract: formatMessage('commencementionContract'), //
    endContract: formatMessage('endСontract'),
    individual: formatMessage('individual'),
    confidant: formatMessage('confidant'),
    powerAttorney: formatMessage('powerAttorney'),
    INN: formatMessage('INN'),
    OKPO: formatMessage('OKPO'),
    bank: formatMessage('bank'),
    BIK: formatMessage('BIK'),
    RS: formatMessage('RS'),
    street: formatMessage('street'),
  };

  return (
    <Row maxW="868px">
      {contract?.type !== ContractType.TYPICAL_CONTRACT && (
        <>
          <Col size="12">
            <Row>
              <FormField
                inputName="individual"
                inputType="text"
                inputValue={contract?.consigneeProfile?.name}
                label={inputLabel.individual}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputName="consigneeHeadFullName"
                inputType="text"
                inputValue={
                  contract?.consigneeRepresentative?.consigneeHeadFullName
                }
                label={inputLabel.supervisorLabel}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputName="consigneeHeadPosition"
                inputType="text"
                inputValue={
                  contract?.consigneeRepresentative?.consigneeHeadPosition
                }
                label={inputLabel.positionLabel}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          {contract?.consigneeRepresentative?.consigneeHeadTrustee
            ?.fullName && (
            <>
              <Col size={{ xs: '12', md: '6' }}>
                <Row>
                  <FormField
                    inputType="text"
                    inputName="headTrusteeName"
                    whiteSpace="nowrap"
                    inputValue={
                      contract?.consigneeRepresentative?.consigneeHeadTrustee
                        ?.fullName
                    }
                    label={inputLabel.confidant}
                    onChange={() => {}}
                    disabled
                  />
                </Row>
              </Col>
              <Col size={{ xs: '12', md: '6' }}>
                <Row>
                  <FormField
                    inputType="text"
                    inputName="trusteeDocument"
                    inputValue={
                      contract?.consigneeRepresentative?.consigneeHeadTrustee
                        ?.trusteeDocument
                    }
                    label={inputLabel.powerAttorney}
                    onChange={() => {}}
                    disabled
                  />
                </Row>
              </Col>
            </>
          )}
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputType="text"
                inputName="country"
                inputValue={contract?.consigneeRepresentative?.country}
                label={inputLabel.country}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputName="city"
                inputType="text"
                inputValue={contract?.consigneeRepresentative?.city}
                label={inputLabel.city}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputType="text"
                inputName="street"
                inputValue={contract?.consigneeRepresentative?.street}
                label={inputLabel.street}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputType="text"
                inputName="INN"
                inputValue={contract?.consigneeRepresentative?.INN}
                label={inputLabel.INN}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputType="text"
                inputName="OKPO"
                inputValue={contract?.consigneeRepresentative?.OKPO}
                label={inputLabel.OKPO}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                inputType="text"
                inputName="bankName"
                inputValue={contract?.consigneeRepresentative?.bankName}
                label={inputLabel.bank}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size="12">
            <Row>
              <FormField
                inputType="text"
                inputName="BIK"
                whiteSpace="nowrap"
                inputValue={contract?.consigneeRepresentative?.BIK}
                label={inputLabel.BIK}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
          <Col size="12">
            <Row>
              <FormField
                inputType="text"
                inputName="bankAccount"
                inputValue={contract?.consigneeRepresentative?.bankAccount}
                label={inputLabel.RS}
                onChange={() => {}}
                disabled
              />
            </Row>
          </Col>
        </>
      )}
      {isDate && (
        <>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                colSize="12"
                disabled
                label={inputLabel.commencementionContract}
                inputName="startDate"
                whiteSpace="nowrap"
                inputType="text"
                inputValue={dateNowFormatMessage(contract?.startDate)}
                required={true}
                tabIndex="3"
                suffixIcon={
                  <CalendarIcon
                    height={20}
                    width={20}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                    }}
                  />
                }
              />
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '6' }}>
            <Row>
              <FormField
                colSize="12"
                disabled
                label={inputLabel.endContract}
                inputName="endDate"
                whiteSpace="nowrap"
                inputType="text"
                inputValue={dateNowFormatMessage(contract?.endDate)}
                required={true}
                tabIndex="3"
                suffixIcon={
                  <CalendarIcon
                    height={20}
                    width={20}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                    }}
                  />
                }
              />
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
}

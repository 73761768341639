// External dependenicies
import { Container, Div, Icon, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';
import DangerButton from '../../buttons/DangerButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import FormField from '../../form/FormField';
import BackNav from '../../nav/BackNav';
import DangerPopup from '../../popup/DangerPopup';
import BasicButtonPrint from '../../print/BasicButtonPrint';
import ErrorPopup from '../../popup/ErrorPopup';
import LoadingPopupContent from '../../popup/LoadingPopup';
import PrimaryPopup from '../../popup/PrimaryPopup';
import SuccessPopup from '../../popup/SuccessPopup';
import SubMenu from '../../topMenu/SubMenu';
import ContractHeader from '../createContracts/ContractHeader';
import { ContractType } from '../createContracts/redux/types';
import ContractsPrint from '../prints/ContractPrint';
import TypicalContractPrint from '../prints/TypicalContractPrint';
import {
  resetUpdateContract,
  resetUpdateContractError,
  updateContract,
  updateContractFields,
} from './redux/actions';
import BottomComponent from './custom-warehouse/BottomComponent';
import DynamicContract from './DynamicContract';
import HeaderContract from './HeaderContract';
import { getContract, resetGetContract } from './redux/actions';
import { GetContractState } from './redux/reducer';
import StartEndDate from './StartEndDates';

export default function ContractDetail() {
  const {
    contract,
    loading,
    deactivationReasonError,
    updateLoading,
    updateError,
    isFormChanged,
    isUpdateSuccess,
    updatedContract,
  } = useSelector((state: RootState): GetContractState => state.getContract);

  const dispatch = useDispatch();
  const id = localStorage.getItem('contractId') as string;

  const [isBackPopup, setBackPopup] = useState(false);
  const [subMenuItem, setSubMenuItem] = useState('contract');
  const [isDeactivatePopup, setDeactivatePopup] = useState(false);
  const [isSaveContractPopup, setSaveContractPopup] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate('/contracts');
    }

    dispatch(getContract(id));

    return () => {
      dispatch(resetGetContract());
      dispatch(resetUpdateContract());
    };
  }, []);

  const sebMenuItems = ['contract'];
  const isActiveContract = contract?.type === ContractType.CUSTOMS_WAREHOUSE;
  const currentTime = moment().utc().valueOf();
  const nextMonth: number = moment(currentTime).add(1, 'M').utc().valueOf();
  const isExpiring =
    nextMonth > contract?.endDate && contract?.endDate >= currentTime;
  const isActiveDate =
    nextMonth > contract?.endDate && contract?.endDate <= currentTime;
  const backNavLabel =
    contract?.consigneeProfile?.name || formatMessage('modelContract');
  const isActive = contract?.type !== ContractType.TYPICAL_CONTRACT;
  const organizationName = contract?.organization?.id && 'A.Сагынов';
  const contractTypeTrans =
    contract?.type === ContractType.TYPICAL_CONTRACT
      ? 'modelContract'
      : contract?.type;

  function contractTypeLabel() {
    return (
      <Div>
        {backNavLabel} (
        <FormattedMessage id={contractTypeTrans || 'contract'} />)
      </Div>
    );
  }

  function onSubmit() {
    dispatch(updateContract(updatedContract));
  }

  function updateFields(e) {
    const { name, value } = e.target;

    dispatch(updateContractFields({ [name]: value }));
  }

  return (
    <Div>
      {loading ? (
        <Div d="flex" justify="center" align="center" minH="90vh">
          <Icon name="Loading" size="40px" />
        </Div>
      ) : (
        <Container>
          <BackNav
            label={contractTypeLabel()}
            onClick={() =>
              isFormChanged ? setBackPopup(true) : navigate('/contracts')
            }
          />
          {isExpiring && (
            <Div
              m={{ b: '2rem' }}
              d="flex"
              flexWrap="nowrap"
              overflow="auto"
              style={{ whiteSpace: 'nowrap' }}
            >
              {sebMenuItems?.map((item, index) => (
                <Div key={index}>
                  <SubMenu
                    menuItem={<FormattedMessage id={item} />}
                    isActive={item === subMenuItem}
                    onClick={() => setSubMenuItem(item)}
                  />
                </Div>
              ))}
              <Div>
                <SubMenu
                  maxWidth="100%"
                  menuItem={`${formatMessage('additionalAgreement')} +`}
                  onClick={() => {}}
                />
              </Div>
            </Div>
          )}
          {contract?.type === ContractType.TYPICAL_CONTRACT ? (
            <StartEndDate contract={contract} />
          ) : !isActiveDate ? (
            <ContractHeader type="UPDATE" isData={!isActiveContract} />
          ) : (
            <HeaderContract isDate={!isActiveContract} />
          )}
          {!isActiveContract ? (
            <DynamicContract contract={contract} isActive={isActiveDate} />
          ) : (
            <BottomComponent contract={contract} isActive={isActiveDate} />
          )}
          <Div d="flex" style={{ gap: '10px' }}>
            <Div maxW="300px" w="100%" m={{ t: '15px' }}>
              <BasicButtonPrint textForBtn={<FormattedMessage id="print" />}>
                <ContractsPrint
                  contract={contract}
                  label={contractTypeLabel()}
                  isActive={isActive}
                />
                <TypicalContractPrint
                  tariffs={contract?.tariffs}
                  isActive={!isActive}
                  ceo={organizationName}
                  organizationLocation="Север"
                />
              </BasicButtonPrint>
            </Div>
            {!isActiveDate && (
              <>
                <Div maxW="300px" w="100%" m={{ t: '15px' }}>
                  <DangerButton
                    size="lg"
                    icon="Delete"
                    onClick={() => setDeactivatePopup(true)}
                  >
                    <FormattedMessage id="deactivate" />
                  </DangerButton>
                </Div>
                <Div maxW="300px" w="100%" m={{ t: '15px' }}>
                  <PrimaryButton
                    size="lg"
                    icon="Checked"
                    onClick={() => setSaveContractPopup(true)}
                  >
                    <FormattedMessage id="save" />
                  </PrimaryButton>
                </Div>
              </>
            )}
          </Div>
          {isBackPopup && (
            <DangerPopup
              title={formatMessage('confirmAction')}
              onClose={() => setBackPopup(false)}
              onSubmit={() => navigate('/contracts')}
              cancelText={formatMessage('cancel')}
              submitText={formatMessage('exit')}
            >
              <FormattedMessage id="newContractExitDescription" />
            </DangerPopup>
          )}
          {isDeactivatePopup && (
            <DangerPopup
              title={formatMessage('deactivateConfigurationText')}
              onClose={() => setDeactivatePopup(false)}
              onSubmit={onSubmit}
              disabled={!contract?.contractEndReason}
              cancelText={formatMessage('cancel')}
              submitText={formatMessage('deactivate')}
            >
              <Div>
                {contractTypeLabel()}
                <Row m={{ t: '20px' }}>
                  <FormField
                    inputName="deactivationReason"
                    inputType="text"
                    whiteSpace="nowrap"
                    placeholder={formatMessage('describeTheReason')}
                    inputValue={contract?.contractEndReason}
                    label={formatMessage('reasonForDeactivation')}
                    error={deactivationReasonError}
                    onChange={updateFields}
                  />
                </Row>
              </Div>
            </DangerPopup>
          )}
          {!updateLoading && isSaveContractPopup && (
            <PrimaryPopup
              title={formatMessage('saveChangesQuestion')}
              onClick={onSubmit}
              btnText={formatMessage('save')}
              loading={updateLoading}
              cancelBtnText={formatMessage('cancel')}
              onClose={() => setSaveContractPopup(false)}
            >
              {contractTypeLabel()}
            </PrimaryPopup>
          )}
          {updateLoading && <LoadingPopupContent maxWidth="300px" />}
          {isUpdateSuccess && (
            <SuccessPopup
              title={
                contract?.deactivationReason
                  ? formatMessage('deactivateted')
                  : formatMessage('contractSaved')
              }
              submitText={formatMessage('close')}
              onSubmit={() => navigate('/contracts')}
            >
              <Div d="block" minW="300px">
                <Text>{contractTypeLabel()}</Text>
                {contract?.deactivationReason && (
                  <Text>
                    <FormattedMessage id="reason" /> - &nbsp;
                    {contract?.deactivationReason}
                  </Text>
                )}
              </Div>
            </SuccessPopup>
          )}
          {updateError && (
            <ErrorPopup
              onSubmit={() => {
                setSaveContractPopup(false);
                dispatch(resetUpdateContractError());
              }}
              title={formatMessage('errorOccurred')}
              submitText={formatMessage('close')}
            >
              {updateError?.message}
            </ErrorPopup>
          )}
        </Container>
      )}
    </Div>
  );
}

/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import ContractDetail from '../../../components/contracts/getContract/Contract';
import Layout from '../../../components/layout';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';

export default function Contract() {
  const { contract } = useSelector((state: RootState) => state.getContract);

  const title = contract?.consigneeProfile?.name || formatMessage('Contracts');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <ContractDetail />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;

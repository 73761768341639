/* External dependencies */
import { Div, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

// Local dependencies
import { dateNowFormatMessage } from '../../common/DateTime';
import { Contract } from '../createContracts/redux/types';
import { getContractFieldValue } from './models';

interface ContractsPrintprops {
  contract?: Contract;
  label?: any;
  isActive: boolean;
  location: string;
}
export default function ContractsPrint({
  contract,
  label,
  isActive,
  location = 'Ceвер',
}: ContractsPrintprops) {
  const contractFieldValue = getContractFieldValue(dateNowFormatMessage);

  const mockData = [
    {
      title: 'Предмет договора',
      itemsChil: [
        {
          title: (
            <FormattedMessage
              id="documentTitleItems1"
              values={{
                location,
                authorName: contract?.consigneeProfile?.name,
                clientName: contract?.consigneeProfile?.name,
                span: (value) => (
                  <Text d="inline" textWeight="600" textSize="sizeForDocument">
                    {value}
                  </Text>
                ),
              }}
            />
          ),
          itemChil: [],
        },
        {
          title: (
            <FormattedMessage
              id="documentTitleItems2"
              values={{
                span: (value) => (
                  <Text d="inline" textWeight="600" textSize="sizeForDocument">
                    {value}
                  </Text>
                ),
              }}
            />
          ),
          itemChil: [],
        },
      ],
    },
    {
      title: 'Обязанности сторон',
      itemsChil: [
        {
          title: '«Исполнитель» обязан:',
          itemChil: [
            {
              title: (
                <FormattedMessage
                  id="documentTitle2Items1"
                  values={{
                    city: contractFieldValue.city,
                    street: contractFieldValue.street,
                    clientName: contract?.consigneeProfile?.name,
                    span: (value) => (
                      <Text
                        textWeight="600"
                        textSize="sizeForDocument"
                        d="inline"
                      >
                        {value}
                      </Text>
                    ),
                  }}
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="documentTitle2Items2"
                  values={{
                    span: (value) => (
                      <Text
                        textWeight="600"
                        textSize="sizeForDocument"
                        d="inline"
                      >
                        {value}
                      </Text>
                    ),
                  }}
                />
              ),
            },
            {
              title: <FormattedMessage id="documentTitle2Items3" />,
            },
            {
              title: <FormattedMessage id="documentTitle2Items4" />,
            },
            {
              title: (
                <FormattedMessage
                  id="documentTitle2Items5"
                  values={{
                    span: (value) => (
                      <Text
                        textWeight="600"
                        textSize="sizeForDocument"
                        d="inline"
                      >
                        {value}
                      </Text>
                    ),
                  }}
                />
              ),
            },
          ],
        },
        {
          title: 'Заказчик обязан:',
          itemChil: [
            {
              title:
                'Производить доставку и передачу груза на хранение с момента подписания настоящего Договора.',
            },
            {
              title: 'Предоставить копии грузо-сопроводительных документов.',
            },
            {
              title:
                'Производить оплату за предоставляемые услуги в порядке и на условиях, предусмотренных настоящим Договором.',
            },
          ],
        },
        {
          title: 'Ответственность сторон:',
          itemChil: [
            {
              title:
                'Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору в соответствии с действующим законодательством КР.',
            },
            {
              title: (
                <FormattedMessage
                  id="documentTitle2Items6"
                  values={{
                    span: (chunks) => (
                      <Text
                        textWeight="600"
                        textSize="sizeForDocument"
                        d="inline"
                      >
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="documentTitle2Items7"
                  values={{
                    span: (value) => (
                      <Text
                        textWeight="600"
                        textSize="sizeForDocument"
                        d="inline"
                      >
                        {value}
                      </Text>
                    ),
                  }}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      title: 'Порядок и условия оплаты',
      itemsChil: [
        {
          title: (
            <FormattedMessage
              id="documentTitle3Items1"
              values={{
                location,
                number: contractFieldValue.number,
                span: (value) => (
                  <Text textWeight="600" textSize="sizeForDocument" d="inline">
                    {value}
                  </Text>
                ),
              }}
            />
          ),
          itemChil: [],
        },
        {
          title: (
            <FormattedMessage
              id="documentTitle3Items2"
              values={{
                span: (value) => (
                  <Text textWeight="600" textSize="sizeForDocument" d="inline">
                    {value}
                  </Text>
                ),
              }}
            />
          ),
          itemChil: [],
        },
      ],
    },

    {
      title: 'Форс-мажорные обстоятельства',
      itemsChil: [
        {
          title: (
            <FormattedMessage
              id="documentTitle4Items1"
              values={{
                span: (value) => (
                  <Text d="inline" textWeight="600" textSize="sizeForDocument">
                    {value}
                  </Text>
                ),
              }}
            />
          ),
          itemChil: [],
        },
      ],
    },
    {
      title: (
        <Text d="inline" textSize="body">
          Срок действия договора
        </Text>
      ),
      itemsChil: [
        {
          title:
            ' Настоящий договор вступает в силу с момента его подписания и действует до расторжения Договора сторонами.',
          itemChil: [],
        },
      ],
    },
    {
      title: 'Прочие условия',
      itemsChil: [
        {
          title:
            'Любые изменения и дополнения к настоящему договору действительны только в том случае, если они составлены в письменной форме и подписаны уполномоченными лицами, представляющими обе стороны.',
          itemChil: [],
        },
        {
          title:
            'Споры и разногласия, которые могут возникнуть в процессе заключения, исполнения или расторжения настоящего Договора, разрешаются сторонами путем переговоров, в противном случае передаются на рассмотрение в судебном порядке.',
          itemChil: [],
        },

        {
          title:
            'Настоящий Договор подписан «29» марта 2021 г. на русском языке на 2 (двух) страницах в 2 (двух) подлинных экземплярах, имеющих равную юридическую силу, по одному экземпляру для каждой из Сторон.',
          itemChil: [],
        },
      ],
    },
  ];

  return (
    isActive && (
      <Div p={{ x: '20px', b: '500px' }}>
        <Div
          d="flex"
          justify="center"
          align="center"
          flexDir="column"
          p={{ t: '10px' }}
        >
          <Text textWeight="600" textSize="body">
            {label}
          </Text>
          {}
        </Div>
        <Div d="flex" justify="space-between" align="center" p={{ t: '40px' }}>
          <Text textWeight="400" textSize="sizeForDocument">
            {contractFieldValue.date}
          </Text>
          <Text textWeight="400" textSize="sizeForDocument">
            <FormattedMessage
              id="contractCity"
              values={{
                city: contract?.consigneeRepresentative?.city,
              }}
            />
          </Text>
        </Div>
        <Div
          d="flex"
          justify="center"
          align="center"
          flexDir="column"
          p={{ t: '10px', b: '10px' }}
        >
          <Text textWeight="400" textSize="sizeForDocument">
            <FormattedMessage
              id="svhDescription"
              values={{
                authorName: contractFieldValue.authorName,
                clientName: contract?.consigneeProfile?.name,
                contractName: contract?.consigneeProfile?.name,
                p: (chunks) => (
                  <Text d="inline" textWeight="600" textSize="sizeForDocument">
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>
        </Div>
        <Div>
          {mockData.map((item, index) => {
            return (
              <Div m={{ t: index == 5 ? '200px' : '0' }}>
                <Div>
                  <Div
                    d="flex"
                    justify="center"
                    align="center"
                    flexDir="column"
                    p={{ t: '20px' }}
                  >
                    <Text textWeight="600" textSize="body">
                      {index + 1}. {item.title}
                    </Text>
                  </Div>
                  <Div>
                    {item.itemsChil.map((chil, idx) => {
                      return (
                        <Div>
                          <Text
                            textSize="sizeForDocument"
                            textWeight={
                              chil.itemChil.length > 0 ? '600' : '400'
                            }
                            p={{ t: '10px' }}
                          >
                            <Text
                              textWeight="600"
                              d="inline"
                              textSize="sizeForDocument"
                            >{`${index + 1}.${idx + 1} `}</Text>
                            {chil.title}
                          </Text>

                          {chil.itemChil.map((cil, indx) => {
                            return (
                              <Text textSize="sizeForDocument" textWeight="400">
                                <Text
                                  textWeight="600"
                                  textSize="sizeForDocument"
                                  d="inline"
                                >{`${index + 1}.${idx + 1}.${
                                  indx + 1
                                }  `}</Text>
                                {cil.title}
                              </Text>
                            );
                          })}
                        </Div>
                      );
                    })}
                  </Div>
                </Div>
              </Div>
            );
          })}
        </Div>
        <Div>
          <Div d="flex" jusify="center">
            <Text
              textSize="body"
              textWeight="600"
              m="0 auto"
              p={{ b: '20px', t: '25px' }}
            >
              <FormattedMessage id="AddressDetails" />
            </Text>
          </Div>
          <Div d="flex">
            <Div
              p="20px 20px 100px 20px"
              w="50%"
              pos="relative"
              style={{ border: '1px solid #8F9BB3', borderRight: '0' }}
            >
              <Text
                textSize="sizeForDocument"
                style={{ textDecoration: 'underline #8F9BB3' }}
              >
                <FormattedMessage id="executor" />
              </Text>
              <Div
                p={{ t: '10px' }}
                d="flex"
                flexDir="column"
                style={{ gap: '3px' }}
              >
                <Text textSize="sizeForDocument">
                  {contractFieldValue.author}
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage id="Kyrgyzstan" />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="contractCity"
                    values={{
                      city: contractFieldValue.city,
                    }}
                  />
                  ,{' '}
                  <FormattedMessage
                    id="street"
                    values={{
                      street: contractFieldValue.street,
                    }}
                  />
                  .{contractFieldValue.street}
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage id="INN" />: {contractFieldValue.inn}
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="OKPOLabel"
                    values={{
                      okpo: contractFieldValue.OKPO,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="FOAO"
                    values={{
                      foao: contractFieldValue.FOAO,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage id="bik" />: {contractFieldValue.bik}
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="checkAccount"
                    values={{
                      checkAccount: contractFieldValue.RS,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="mobillePhone"
                    values={{
                      phone: contractFieldValue.mobillePhone,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="email"
                    values={{
                      email: contractFieldValue.email,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage id="bank" />: {contractFieldValue.FOAO}
                </Text>
                <Div
                  d="flex"
                  justify="space-between"
                  aling="center"
                  m={{ t: '50px' }}
                  p={{ t: '10px' }}
                  pos="absolute"
                  w="88%"
                  bottom="10px"
                  style={{ borderTop: '1px solid black' }}
                >
                  <Text textSize="sizeForDocument">
                    <FormattedMessage id="placeOfPrinting" />
                  </Text>
                  <Text textSize="sizeForDocument">Сагынов А. С.</Text>
                  <Text textSize="sizeForDocument">
                    (<FormattedMessage id="signature" />)
                  </Text>
                </Div>
              </Div>
            </Div>
            <Div
              p="20px"
              w="50%"
              pos="relative"
              style={{ border: '1px solid #8F9BB3' }}
            >
              <Text
                textSize="sizeForDocument"
                style={{ textDecoration: 'underline #8F9BB3' }}
              >
                <FormattedMessage id="customer" />
              </Text>
              <Div
                p={{ t: '10px' }}
                d="flex"
                flexDir="column"
                style={{ gap: '3px' }}
              >
                <Text textSize="sizeForDocument">{label}</Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage id="Kyrgyzstan" />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="cityLabel"
                    values={{
                      city: contract?.consigneeRepresentative?.city,
                    }}
                  />
                  ,{' '}
                  <FormattedMessage
                    id="streetLabel"
                    values={{
                      street: contract?.consigneeRepresentative?.street,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="INNLabel"
                    values={{
                      inn: contract?.consigneeRepresentative?.INN,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="OKPOLabel"
                    values={{
                      okpo: contract?.consigneeRepresentative?.OKPO,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="bankLabel"
                    values={{
                      bankCode: contract?.consigneeRepresentative?.bankName,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="BIKLabel"
                    values={{
                      bik: contract?.consigneeRepresentative?.BIK,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage
                    id="checkAccount"
                    values={{
                      checkAccount:
                        contract?.consigneeRepresentative?.bankAccount,
                    }}
                  />
                </Text>
                <Text textSize="sizeForDocument">
                  <FormattedMessage id="powerAttorney" />
                  {': ' +
                    contract?.consigneeRepresentative?.consigneeHeadTrustee
                      ?.trusteeDocument}
                </Text>
                <Div
                  d="flex"
                  justify="space-between"
                  pos="absolute"
                  bottom="10px"
                  w="88%"
                  aling="center"
                  m={{ t: '50px' }}
                  p={{ t: '10px' }}
                  style={{ borderTop: '1px solid black' }}
                >
                  <Text textSize="sizeForDocument">
                    <FormattedMessage id="placeOfPrinting" />
                  </Text>
                  <Text textSize="sizeForDocument">
                    {contract?.consigneeRepresentative?.consigneeHeadFullName}
                  </Text>
                  <Text textSize="sizeForDocument">
                    (<FormattedMessage id="signature" />)
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    )
  );
}

// External dependencies
import { Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Local dependencies
import formatMessage from '../../../intl/formatMessage';
import FormField from '../../form/FormField';
import SubMenu from '../../topMenu/SubMenu';
import { Contract } from '../createContracts/redux/types';
import { updateContractFields } from './redux/actions';

interface DynamicContractProps {
  contract?: Contract;
  isActive?: boolean;
}

export default function DynamicContract({
  contract,
  isActive,
}: DynamicContractProps) {
  const [subMenuItem, setSubMenuItem] = useState(0);

  const dispatch = useDispatch();

  function updateFieldsValue(e, idx, itemIndex) {
    const data = {
      index: subMenuItem,
      item: { index: idx, item: { index: itemIndex } },
      value: e.target.value,
    };

    dispatch(updateContractFields({ fieldValue: data }));
  }

  return (
    <>
      <Div
        d="flex"
        flexWrap="nowrap"
        overflow="auto"
        style={{ whiteSpace: 'nowrap' }}
      >
        {contract?.tariffs?.map((item, index) => (
          <Div key={index}>
            <SubMenu
              maxWidth="100%"
              menuItem={item?.name}
              isActive={index === subMenuItem}
              onClick={() => setSubMenuItem(index)}
            />
          </Div>
        ))}
      </Div>
      <Div m={{ t: '2rem' }}>
        {contract?.tariffs &&
          contract?.tariffs[subMenuItem]?.fields?.map((field, index) => (
            <Row key={index}>
              <Col size={{ xs: '12px', md: '9' }}>
                <Div d="flex">
                  <Text
                    m="0.5rem 0"
                    textWeight="600"
                    textColor="input_placeholer_color"
                  >
                    {index + 1}.
                  </Text>
                  <FormField
                    inputValue={field?.name}
                    inputType="text"
                    inputName="fieldName"
                    disabled
                    error={field?.nameError}
                  />
                </Div>
              </Col>
              <Col size={{ xs: '12', md: '3' }}>
                <Div d="flex" m={{ l: { xs: '10px', md: '0' } }}>
                  {field?.values?.map((value, itemIndex) => (
                    <Div key={itemIndex}>
                      <FormField
                        inputName="field"
                        inputValue={value?.value}
                        inputType="text"
                        disabled={isActive}
                        fieldDescription={formatMessage(value?.unit)}
                        onChange={(e) => updateFieldsValue(e, index, itemIndex)}
                        error={value?.valueError}
                      />
                    </Div>
                  ))}
                </Div>
              </Col>
            </Row>
          ))}
      </Div>
      <Div>
        <Text
          textWeight="600"
          textColor="input_placeholer_color"
          textSize="small"
          p={{ b: '1rem' }}
        >
          <FormattedMessage id="note" />
        </Text>
        <Div bg="table_cell_background" p="15px" rounded="md">
          <Text textColor="table_cell_text_color">
            * Первые трое суток считаются как за одни сутки, по истечении
            третьих суток нахождения груза на терминале, (на четвертые)
            начисляется ежесуточный простой по вышеуказанным тарифам. В режиме
            ВТТ период нахождения грузов и автотранспортных средств во
            внерабочее время на территории терминала приравнивается к одним
            суткам и оплачивается в полном объеме.
          </Text>
        </Div>
      </Div>
    </>
  );
}

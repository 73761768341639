// External  dependencies
import { Col, Row } from 'atomize';
import React from 'react';
import { useDispatch } from 'react-redux';

// Local dependencies
import CalendarIcon from '../../../../assets/svg/calendar';
import formatMessage from '../../../../intl/formatMessage';
import { dateNowFormatMessage } from '../../../common/DateTime';
import FormField from '../../../form/FormField';
import { defaultInputStyle } from '../../../form/FormStyles';
import { updateContractFields } from '../redux/actions';

export default function BottomComponent({ contract, isActive }) {
  const inputLabel = {
    cargoLabel: formatMessage('cargo'),
    addCargoDescriptionPlaceholder: formatMessage('addCargoDescription'),
    startContractDateLabel: formatMessage('startContractDate'),
    tarif: formatMessage('tarif'),
    tarifFieldDescription: formatMessage('contractCarParkingWithCargoFare'),
  };

  const dispatch = useDispatch();

  function updateFieldsValue(e, idx, itemIndex) {
    const data = {
      index: 0,
      item: { index: idx, item: { index: itemIndex } },
      value: e.target.value,
    };

    dispatch(updateContractFields({ fieldValue: data }));
  }

  return (
    <Row p={{ r: '7px' }} maxW="883px">
      <Col>
        {contract?.tariffs?.map((tariff, index) =>
          tariff.fields.map((field, idx) => (
            <FormField
              key={idx}
              label={inputLabel.cargoLabel}
              inputName="itemValue"
              inputType="textarea"
              inputValue={field.name}
              error={field.nameError}
              tabIndex="3"
              disabled
              placeholder={inputLabel.addCargoDescriptionPlaceholder}
              inputStyle={{
                ...defaultInputStyle,
                fontFamily: 'Open Sans',
              }}
            />
          )),
        )}
        <Row>
          <Col size={{ xs: '12', sm: '6' }}>
            <FormField
              label={inputLabel.startContractDateLabel}
              inputName="startaDate"
              inputType="text"
              whiteSpace="nowrap"
              inputValue={dateNowFormatMessage(contract?.startDate)}
              onChange={() => {}}
              disabled
              inputStyle={{
                cursor: 'pointer',
                ...defaultInputStyle,
              }}
              suffixIcon={
                <CalendarIcon
                  height={20}
                  width={20}
                  style={{ position: 'absolute', top: '10px', right: '10px' }}
                />
              }
            />
          </Col>
          <Col size={{ xs: '12', sm: '6' }}>
            {contract?.tariffs?.map((tariff, index) =>
              tariff.fields.map((field, idx) =>
                field.values.map(({ value, valueError }, itemIndex) => (
                  <FormField
                    key={itemIndex}
                    label={inputLabel.tarif}
                    inputName="itemValue"
                    inputType="number"
                    disabled={isActive}
                    error={valueError}
                    inputValue={value}
                    onChange={(e) => updateFieldsValue(e, idx, itemIndex)}
                    fieldDescription={inputLabel.tarifFieldDescription}
                  />
                )),
              ),
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

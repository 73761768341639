// External dependencies
import { Div, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

// Local dependencies
import formatMessage from '../../../intl/formatMessage';
import { dateNowFormatMessage } from '../../common/DateTime';
import { Tariff } from '../createContracts/redux/types';
import './style.scss';

interface TypicalContractPrintProps {
  isActive: boolean;
  ceo: string;
  organizationLocation: string;
  tariffs: Tariff[];
}

export default function TypicalContractPrint({
  tariffs,
  isActive,
  ceo = 'A.Cагынов',
  organizationLocation = 'Север',
}: TypicalContractPrintProps) {
  const director = `/_______________/ ${ceo}`;
  const currentTime = dateNowFormatMessage(new Date().getTime());

  return (
    <Div w="100%" p={'20px'}>
      {isActive ? (
        <Div
          pos="absolute"
          right="35px"
          top="10px"
          maxW="150px"
          textAlign="center"
        >
          <Text textSize="sizeForDocument">
            <FormattedMessage
              id="approveContract"
              values={{
                organization: organizationLocation,
              }}
            />
            {director} <br />
            {currentTime}
          </Text>
        </Div>
      ) : (
        <Text textSize="display1" textWeight="600" p={{ b: '20px' }}>
          <FormattedMessage id="app" /> №1
        </Text>
      )}
      <Div m={{ t: isActive ? '70px' : '0' }}>
        {tariffs?.map((tariff, index) => (
          <Div key={tariff.name}>
            <Div d="flex" justify="center" p={{ y: '20px' }}>
              <Text textWeight="600" textSize="small">
                {index + 1}. {tariff.name}
              </Text>
            </Div>
            <table className="contract-print-teble">
              <tbody>
                <tr>
                  <th className="th1-head">№</th>
                  <th className="th2">
                    <FormattedMessage id="service" />
                  </th>
                  <th className="th3">
                    <FormattedMessage id="tarif" />
                  </th>
                </tr>
                {tariff.fields.map((field, idx) => (
                  <tr key={field.name}>
                    <td className="th1">{idx + 1}</td>
                    <td>{field.name}</td>
                    <td>
                      {field?.values?.map((value, index) => (
                        <React.Fragment key={index}>
                          {value.value} {formatMessage(value.unit)} <br />
                        </React.Fragment>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Div>
        ))}
      </Div>
      {!isActive && (
        <Div
          m={{ t: '150px' }}
          w="200px"
          style={{ borderTop: '1px solid #2E3A59' }}
          textAlign="center"
        >
          <Text textSize="little">М.П.</Text>
        </Div>
      )}
    </Div>
  );
}

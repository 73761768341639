// external dependencies
import { Col, Row } from 'atomize';
import React from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import CalendarIcon from '../../../assets/svg/calendar';
import formatMessage from '../../../intl/formatMessage';
import { dateNowFormatMessage } from '../../common/DateTime';
import FormField from '../../form/FormField';

interface StartEndDateProps {
  contract: any;
}

export default function StartEndDate({ contract }: StartEndDateProps) {
  return (
    <Row maxW="868px">
      <Col size={{ xs: '12', md: '6' }}>
        <Row>
          <FormField
            colSize="12"
            disabled
            label={formatMessage('commencementionContract')}
            inputName="startDate"
            whiteSpace="nowrap"
            inputType="text"
            inputValue={dateNowFormatMessage(contract?.startDate)}
            required={true}
            tabIndex="3"
            suffixIcon={
              <CalendarIcon
                height={20}
                width={20}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                }}
              />
            }
          />
        </Row>
      </Col>
      <Col size={{ xs: '12', md: '6' }}>
        <Row>
          <FormField
            colSize="12"
            disabled
            label={formatMessage('endСontract')}
            inputName="endDate"
            whiteSpace="nowrap"
            inputType="text"
            inputValue={dateNowFormatMessage(contract?.endDate)}
            required={true}
            tabIndex="3"
            suffixIcon={
              <CalendarIcon
                height={20}
                width={20}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                }}
              />
            }
          />
        </Row>
      </Col>
    </Row>
  );
}
